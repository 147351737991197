.game h1 {
    text-align: center;
    font-size: 50px;
}

.game p {
    text-align: center;
}

.game table {
    margin: 0 auto;
}

.buttons {
    margin: 0 auto;
    display: flex;
    width: 500px;
}

.button {
    background-color: #303030;
    color: #50C8FF;
    height: 30px;
    width: 100px;
    border-radius: 5px;
    margin: 0 auto;
    margin-bottom: 25px;
    text-align: center;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    transition: background-color 0.5s, color 0.5s;
}

.button:hover {
    background-color: #50C8FF;
    color: #303030;
    transition: background-color 0.5s, color 0.5s;
}

.cell {
    height: 100px;
    width: 100px;
    background-color: #303030;
    border-radius: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.color-2 {
    background-color: #50C8FF;
}

.color-4 {
    background-color: green;
}

.color-8 {
    background-color: red;
}

.color-16 {
    background-color: orange;
}

.color-32 {
    background-color: yellow;
}

.color-64 {
    background-color: blue;
}

.color-128 {
    background-color: purple;
}

.color-256 {
    background-color: pink;
}

.color-512 {
    background-color: #50C8FF;
}

.color-1024 {
    background-color: green;
}

.color-2048 {
    background-color: blue;
}

.number {
    color: #FFF;
    font-size: 35px;
}

.score {
    text-align: center;
    margin-bottom: 20px;
}